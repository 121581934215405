/* CSS Libraries Used 

*Animate.css by Daniel Eden.
*FontAwesome 4.7.0
*Typicons

*/

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');

/* body, html {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #1d243d;
  padding: 0;
  margin: 0;
} */

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
}

.container2 {
  margin: 0;
  top: 50px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  background-color: rgb(33, 41, 66);
  border-radius: 9px;
  border-top: 10px solid #79a6fe;
  border-bottom: 10px solid #8BD17C;
  width: 400px;
  height: 500px;
  box-shadow: 1px 1px 108.8px 19.2px rgb(25, 31, 53);
}

.container3 {
  margin: 0;
  top: 50px;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  background-color: rgb(33, 41, 66);
  border-radius: 9px;
  border-top: 10px solid #79a6fe;
  border-bottom: 10px solid #8BD17C;
  width: 70%;
  height: 80%;
  box-shadow: 1px 1px 108.8px 19.2px rgb(25, 31, 53);
}

.box h4 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #5c6bc0;
  font-size: 18px;
  margin-top: 60px;
  ;
}

.box h4 span {
  color: #dfdeee;
  font-weight: lighter;
}

.box h5 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 13px;
  color: #a1a4ad;
  letter-spacing: 1.5px;
  margin-top: -15px;
  margin-bottom: 70px;
}

.box input[type="text"],
.box input[type="password"] {
  display: block;
  margin: 20px auto;
  background: #262e49;
  border: 0;
  border-radius: 5px;
  padding: 14px 10px;
  width: 320px;
  outline: none;
  color: #d6d6d6;
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;

}

.mt-5 {
  margin-top: 100px;
}

::-webkit-input-placeholder {
  color: #565f79;
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  border: 1px solid #79A6FE;

}

a {
  color: #5c7fda;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

label input[type="checkbox"] {
  display: none;
}


label span {
  height: 13px;
  width: 13px;
  border: 2px solid #464d64;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  /* float: left; */
  left: 7.5%;
}

.btn1 {
  border: 0;
  background: #7f5feb;
  color: #dfdeee;
  border-radius: 100px;
  width: 340px;
  height: 49px;
  font-size: 16px;
  position: absolute;
  top: 79%;
  left: 8%;
  transition: 0.3s;
  cursor: pointer;
}

.btn1:hover {
  background: #5d33e6;
}

.rmb {
  position: absolute;
  margin-left: -24%;
  margin-top: 0px;
  color: #dfdeee;
  font-size: 13px;
}

.forgetpass {
  position: relative;
  float: right;
  right: 28px;
}

.dnthave {
  position: absolute;
  top: 92%;
  left: 24%;
}

.dnthaveadmin {
  position: absolute;
  top: 92%;
  left: 40%;
}

[type=checkbox]:checked+span:before {
  font-family: FontAwesome;
  font-size: 16px;
  content: "\f00c";
  position: absolute;
  top: -4px;
  color: #896cec;
  left: -1px;
  width: 13px;
}

.typcn {
  position: absolute;
  left: 339px;
  top: 282px;
  color: #3b476b;
  font-size: 22px;
  cursor: pointer;
}

.typcn.active {
  color: #7f60eb;
}

.error {
  background: #ff3333;
  text-align: center;
  width: 337px;
  height: 20px;
  padding: 2px;
  border: 0;
  border-radius: 5px;
  margin: 10px auto 10px;
  position: absolute;
  top: 31%;
  left: 7.2%;
  color: white;
  display: none;
}

.footer {
  position: relative;
  left: 0;
  bottom: 0;
  top: 605px;
  width: 100%;
  color: #78797d;
  font-size: 14px;
  text-align: center;
}

.footer .fa {
  color: #7f5feb;
  ;
}

.aline {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}


.futer {
  background-color: #000000;
  color: rgb(255, 255, 255);
  height: 300px;
  margin-top: -3px;
}

.profimg {
  border-radius: 50%;
  margin-top: 50px;
  filter: drop-shadow(0px 10px 10px #000000);
}

.playv {
  
  padding: 5px 30px 5px 30px;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  text-decoration-style: none;
  text-decoration: none;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 1px;
  margin-right: 5px;
}

.playva {
  
  padding: 5px 30px 5px 30px;
  color: rgb(0, 0, 0);
  border-radius: 5px;
  text-decoration-style: none;
  text-decoration: none;
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 1px;
  margin-right: 5px;
}
.playva:hover {
  background-color: #ff3d3d;
  color: rgb(255, 255, 255);
  border-style: dotted;
  border-width: 0px;
  border-color: #ff3d3d;
  border-width: 1px;
}


.playv:hover {
  background-color: #ff3d3d;
  color: rgb(255, 255, 255);
  border-style: dotted;
  border-width: 0px;
  border-color: #ff3d3d;
  border-width: 1px;
}


#playv:hover {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-color: rgb(255, 255, 255);
  border-style: dotted;
  border-width: 1px;
  text-decoration: none;

}

.userinfot{
  margin-left: 25%;
  text-align: left;
  align-items: center;
  text-decoration: none;
  margin-bottom: 5%;
  padding-left: 10%;
  margin-top:40px;
  list-style-type:none
}