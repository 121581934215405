.App {
  max-width: 1200px;
  color: #fff;
  margin: 30px auto;
  padding: 0 15px;
}

.container {
  margin-left: 70px;
}

/* PANEL */

.panel {
  width: 55px;
  height: 342px;
  position: fixed;
  top: 20px;
  background-color: hsl(223, 36%, 14%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.panel div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.panel svg {
  font-size: 26px;
  cursor: pointer;
  color: hsl(221, 23%, 43%);
}

.panel svg:hover {
  opacity: 0.7;
}

.panel svg.active {
  color: #fff;
}

/* MOVIES */

.popular-movies {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.section-title {
  color: hsl(223, 36%, 84%);
  height: 100%;
  margin: 35px 0;
}

/* MOVIE */

.movie {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.movie:hover {
  box-shadow: 0px 0px 24px 0px hsl(223, 36%, 36%);
}

.movie .shadow {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.75) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.movie h2 {
  margin: 15px 10px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  font-size: 1rem;
}

.movie img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.movie svg {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.6;
}

.movie svg:hover {
  opacity: 1;
}

/* FILTER */

.filter-container {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.filter-container button {
  background-color: hsl(223, 36%, 16%);
  color: hsl(223, 36%, 94%);
  font-family: "Inter", sans-serif;
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: 500;
}

.filter-container button:hover {
  background-color: hsl(223, 36%, 24%);
}

.filter-container button.active {
  background-color: hsl(224, 32%, 94%);
  color: hsl(224, 32%, 9%);
}

.filter-container button.more {
  color: hsl(221, 23%, 63%);
  background-color: transparent;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 0 10px;
}

.filter-container button.more:hover {
  background-color: hsl(223, 36%, 24%);
}

/* SEARCH */

.search-movies {
  width: 100%;
  margin-top: 15px;
  padding: 10px 0;
  border-radius: 25px;
}

.search-movies svg {
  font-size: 16px;
}

.search-movies input {
  background-color: transparent;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: hsl(224, 32%, 94%);
  margin-left: 15px;
  width: 80%;
}

.search-movies input:focus {
  outline: none;
}

/* MOVIE DETAILS */

.back {
  height: max-content;
  margin-top: 10px;
  width: 55px;
  position: fixed;
  top: 20px;
  background-color: hsl(223, 36%, 14%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.back svg {
  font-size: 20px;
  cursor: pointer;
  color: hsl(221, 23%, 73%);
}

.back svg:hover {
  opacity: 0.7;
}

.back svg.active {
  color: #fff;
}

.img-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -2;
  opacity: 0.1;
  object-fit: cover;
}

.details h4 {
  margin: 0;
  color: hsl(223, 36%, 92%);
}

.details ul {
  padding: 0;
  list-style: none;
  color: hsl(223, 36%, 82%);
  margin-bottom: 20px;
  margin-top: 10px;
}

.details p {
  color: hsl(223, 36%, 82%);
  margin-bottom: 20px;
  margin-top: 5px;
}

.details a {
  text-decoration: none;
  color: hsl(223, 36%, 75%);
}

.details a:hover {
  opacity: 0.7;
}

#rate {
  color: yellow;

  border-radius: 15px;
  width: max-content;
}
#rate svg {
  margin-right: 5px;
}


.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10%;
}

.loginbg {
  color: rgb(0, 0, 0);
  margin-top: 5%;
  background-color: #ffffff;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  padding-bottom: 5%;
  margin-left: 35%;
  border-radius: 5%;
  box-shadow: 30px 30px 50px #000000;
  margin-bottom: 50px;
  opacity: 0.99;
}

.form-control {
  background-color: #ffffff;
  break-after: always;
  border-top: red;
  border-left: red;
  border-right: red;
}

#flexCheckDefault {
  align-items: center;
}

.backimg {
  height: 25px;
  margin-left: 5px;
}
.btn-secondary {
  width: 100%;
  margin-bottom: 10px;
}
.a {
  margin-left: 50%;
}
.futer {
background-color: #000000;
color: rgb(255, 255, 255);
height: 300px;
margin-top: -3px;
}

.futera {
padding-top: 50px;
text-align: center;
text-decoration: none;
}

.aline{
text-align: center;
margin-top: 20px;
}


.loginlg {
  text-align: center;
}

.netflix-player-wrapper {
  font-size: 16px;
}

#netflix-player {
  position: absolute;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  overflow: hidden;
  background: #ccc;
  font-size: 1em;
}

#player-sizing {
  position: absolute;
  width: 1em;
  height: 1em;
  visibility: hidden;
  font-size: 1em;
}

#ten-percent-height {
  position: absolute;
  width: 80%;
  height: 10em;
  left: 10%;
  bottom: 8em;
  background: #000;
  display: flex;
}

#ten-percent-height>p {
  display: block;
  margin: 1em;
  font-size: 2em;
  color: #fff;
}



